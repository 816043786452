import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Tag } from "antd";

import getBookingRevisionErrors from "utils/get_booking_revision_errors";

import { BookingRevisionStatusTag } from "../booking_revision_status_tag";

class BookingRevisionTags extends Component {
  renderErrorTags = () => {
    const { t, revision } = this.props;
    const errors = getBookingRevisionErrors(revision);

    const errorTags = errors.map((error, key) => (
      <Tag color="red" key={key}>
        {t(`bookings_page:booking_view_dialog:error:${error}`)}
      </Tag>
    ));

    return errorTags;
  };

  render() {
    const { revision } = this.props;
    const { status, is_crs_revision } = revision;

    return (
      <>
        {is_crs_revision && <Tag color="blue">{this.props.t("bookings_page:booking_view_dialog:crs_revision")}</Tag>}
        <BookingRevisionStatusTag status={status} />
        {this.renderErrorTags()}
      </>
    );
  }
}

export default withTranslation()(BookingRevisionTags);
