import React from "react";

import { EventDescription } from "./event_description";
import { EventIpAddress } from "./event_ip_address";
import { EventRevision } from "./event_revision";
import { EventTitle } from "./event_title";
import { EventUser } from "./event_user";

export function SystemEventWithRevision({ event }) {
  return (
    <>
      <EventTitle event={event} />
      <EventDescription>
        <EventRevision value={event.booking_revision_id} />
        {event.ip_address && <EventIpAddress value={event.ip_address} />}
        {event.user_email && <EventUser username={event.user_name} email={event.user_email} />}
      </EventDescription>
    </>
  );
}
