import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { DownOutlined, SyncOutlined } from "@ant-design/icons";
import { Button, Dropdown, message, Modal, Space } from "antd";

import showErrorMessageFromResponse from "utils/show_error_message_from_response";

import { ListingUnpublishWorkflow } from "./listing_unpublish_workflow/listing_unpublish_workflow";
import { Status } from "./status";

import styles from "./listing_row.module.css";

const SYNC_DELAY = 1000;

export default function AirBnbListing(props) {
  const { t } = useTranslation();
  const {
    channelId,
    listing,
    onPublishClick,
    onListingUnpublished,
    onAvailabilityClick,
    onPriceClick,
    onBookingSettingsClick,
    onSyncClick,
    onPullFutureReservationsClick,
    onReloadClick,
    onCheckoutTasksClick,
  } = props;
  const { title, published, listing_id: listingId, sync_category: syncCategory } = listing;
  const isListingLoading = syncCategory === "pending";
  const [unpublishModalOpen, setUnpublishModalOpen] = React.useState(false);

  const handlePublishClick = useCallback(() => {
    return onPublishClick(listingId);
  }, [listingId, onPublishClick]);

  const handleUnpublishClick = () => {
    setUnpublishModalOpen(true);
  };

  const handleAvailabilityClick = useCallback(() => {
    onAvailabilityClick(listingId);
  }, [listingId, onAvailabilityClick]);

  const handlePriceClick = useCallback(() => {
    onPriceClick(listingId);
  }, [listingId, onPriceClick]);

  const handleBookingSettingsClick = useCallback(() => {
    onBookingSettingsClick(listingId);
  }, [listingId, onBookingSettingsClick]);

  const handleSyncClick = useCallback(() => {
    const emulatedDelay = new Promise((resolve) => setTimeout(resolve, SYNC_DELAY));

    return Promise.all([onSyncClick(listingId), emulatedDelay])
      .then(() => {
        message.success(t("channels_page:form:listing:sync_success"));

        return null;
      })
      .catch(showErrorMessageFromResponse);
  }, [t, listingId, onSyncClick]);

  const handleLoadFutureReservationClick = useCallback(() => {
    const emulatedDelay = new Promise((resolve) => setTimeout(resolve, SYNC_DELAY));

    return Promise.all([onPullFutureReservationsClick(listingId), emulatedDelay])
      .then(() => {
        message.success(t("channels_page:form:listing:sync_success"));

        return null;
      })
      .catch(showErrorMessageFromResponse);
  }, [t, listingId, onPullFutureReservationsClick]);

  const handleReloadClick = useCallback(() => {
    return onReloadClick(listingId);
  }, [listingId, onReloadClick]);

  const settingItems = [{
    key: "publish",
    label: published ? t("channels_page:form:listing:unpublish") : t("channels_page:form:listing:publish"),
    onClick: published ? handleUnpublishClick : handlePublishClick,
    disabled: isListingLoading,
  }, {
    type: "divider",
  }, {
    key: "price",
    label: t("channels_page:form:listing:price_settings"),
    onClick: handlePriceClick,
    disabled: isListingLoading,
  }, {
    key: "availability",
    label: t("channels_page:form:listing:availability_settings"),
    onClick: handleAvailabilityClick,
    disabled: isListingLoading,
  }, {
    key: "booking",
    label: t("channels_page:form:listing:booking_settings"),
    onClick: handleBookingSettingsClick,
    disabled: isListingLoading,
  }, {
    key: "checkout_tasks",
    label: t("channels_page:form:listing:checkout_tasks"),
    onClick: () => onCheckoutTasksClick(listingId),
    disabled: isListingLoading,
  }, {
    type: "divider",
  }, {
    key: "refresh",
    label: t("channels_page:form:listing:refresh"),
    onClick: handleReloadClick,
  }, {
    key: "sync",
    label: t("channels_page:form:listing:sync"),
    onClick: handleSyncClick,
  }, {
    key: "load_future_reservations",
    label: t("issues:types:load_future_reservations"),
    onClick: handleLoadFutureReservationClick,
  }];

  return (
    <div className={styles.listingRow}>
      <div className={styles.listingTitle}>
        {`${title} (${listingId})`}
        {isListingLoading && <SyncOutlined spin className={styles.listingLoadingIcon} />}
      </div>
      <Status listing={listing} />
      <Dropdown menu={{ items: settingItems }}>
        <Button type="link" disabled={isListingLoading}>
          <Space>
            {t("general:actions")}
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
      <Modal
        open={unpublishModalOpen}
        footer={null}
        maskClosable
        destroyOnClose
        width={600}
        onCancel={() => { setUnpublishModalOpen(false); }}
      >
        <ListingUnpublishWorkflow
          channelId={channelId}
          listingId={listingId}
          onSuccess={() => {
            onListingUnpublished(listingId);
            setUnpublishModalOpen(false);
          }}
          onCancel={() => { setUnpublishModalOpen(false); }}
        />
      </Modal>
    </div>
  );
}
